var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-full-dialog",
      attrs: {
        visible: _vm.visible,
        fullscreen: "",
        "lock-scroll": "",
        "show-close": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "SNOWS-full-dialog-header" }, [
        _c("div", { staticClass: "header-title" }, [
          _c("img", {
            staticClass: "header-logo",
            attrs: { src: require("@/assets/images/snow.png") },
          }),
          _c("p", { staticClass: "header-txt" }, [_vm._v(" · 代码预览")]),
        ]),
        _c(
          "div",
          { staticClass: "options" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "SNOWS-common-layout main",
        },
        [
          _c(
            "div",
            { staticClass: "SNOWS-common-layout-left" },
            [
              _c("el-tree", {
                ref: "treeBox",
                staticClass: "SNOWS-common-el-tree",
                attrs: {
                  data: _vm.treeData,
                  props: _vm.defaultProps,
                  "default-expand-all": "",
                  "highlight-current": "",
                  "expand-on-click-node": false,
                  "node-key": "id",
                },
                on: { "node-click": _vm.handleNodeClick },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node }) {
                      return _c(
                        "span",
                        { staticClass: "custom-tree-node" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: node.label,
                                placement: "bottom",
                              },
                            },
                            [
                              _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(node.label)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "SNOWS-common-layout-center" }, [
            _c(
              "div",
              { staticClass: "SNOWS-common-layout-main" },
              [
                _c("JNPFCodeEditor", {
                  ref: "CodeEditor",
                  attrs: { options: _vm.options },
                  model: {
                    value: _vm.currentContent,
                    callback: function ($$v) {
                      _vm.currentContent = $$v
                    },
                    expression: "currentContent",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }