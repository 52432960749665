<template>
  <el-dialog :title="webType?'更改模式':'新建功能'" :close-on-click-modal="false" lock-scroll
    v-bind="$attrs" v-on="$listeners" class="SNOWS-dialog SNOWS-dialog_center SNOWS-dialog-add"
    :width="webType?'310px':'600px'">
    <div class="add-main">
      <div class="add-item add-item-left" @click="add(1)" v-if="webType!==1">
        <i class="add-icon el-icon-document"></i>
        <div class="add-txt">
          <p class="add-title">表单</p>
          <p class="add-desc">纯表单设计</p>
        </div>
      </div>
      <div class="add-item add-item-list" @click="add(2)" v-if="webType!==2&&webType!==3">
        <i class="add-icon icon-ym icon-ym-sysMenu"></i>
        <div class="add-txt">
          <p class="add-title">列表</p>
          <p class="add-desc">表单+列表设计</p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ['webType'],
  methods: {
    add(type) {
      this.$emit('add', type, this.webType ? true : false)
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style scoped>
.add-txt p{
  margin: 0;
}
</style>
