var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "功能表单",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add": _vm.openAddBox },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOption,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "row-toggleWebType": _vm.toggleWebType,
          "row-copy": _vm.copy,
          "row-download": _vm.download,
          "row-preview": _vm.preview,
        },
      }),
      _vm.formVisible
        ? _c("Form", { ref: "Form", on: { close: _vm.closeForm } })
        : _vm._e(),
      _c("AddBox", {
        attrs: { visible: _vm.addVisible, webType: _vm.currWebType },
        on: {
          "update:visible": function ($event) {
            _vm.addVisible = $event
          },
          add: _vm.handleAdd,
        },
      }),
      _vm.previewVisible
        ? _c("Preview", {
            ref: "preview",
            on: {
              close: function ($event) {
                _vm.previewVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.downloadFormVisible
        ? _c("DownloadForm", {
            ref: "downloadForm",
            on: {
              close: function ($event) {
                _vm.downloadFormVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }