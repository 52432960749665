var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title: "输出设置",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { "label-width": "100px", model: _vm.dataForm },
        },
        [
          _vm.type != 3
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "模块命名",
                    prop: "module",
                    rules: [
                      {
                        required: true,
                        message: "请选择模块命名",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择模块命名",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.dataForm.module,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "module", $$v)
                        },
                        expression: "dataForm.module",
                      },
                    },
                    _vm._l(_vm.moduleList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "功能描述",
                prop: "description",
                rules: [
                  {
                    required: true,
                    message: "功能描述不能为空",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "功能描述" },
                model: {
                  value: _vm.dataForm.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "description", $$v)
                  },
                  expression: "dataForm.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "功能类名",
                prop: "className",
                rules: [
                  {
                    required: true,
                    message: "功能类名不能为空",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "功能类名" },
                model: {
                  value: _vm.dataForm.className,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "className", $$v)
                  },
                  expression: "dataForm.className",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.dataForm.subClassName, function (item, i) {
            return _c(
              "el-form-item",
              {
                key: i,
                attrs: {
                  label: item.placeholderText,
                  prop: "subClassName." + i + ".fullName",
                  rules: item.rules,
                },
              },
              [
                _c("el-input", {
                  attrs: { placeholder: item.placeholderText },
                  model: {
                    value: item.fullName,
                    callback: function ($$v) {
                      _vm.$set(item, "fullName", $$v)
                    },
                    expression: "item.fullName",
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("下载代码")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }