<template>
  <div class="center">
    <head-layout head-title="功能表单"
                 :head-btn-options="headBtnOptions"
                 @head-add="openAddBox"></head-layout>
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :table-options="tableOption"
                 :table-data="tableData"
                 :table-loading="tableLoading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @row-toggleWebType="toggleWebType"
                 @row-copy="copy"
                 @row-download="download"
                 @row-preview="preview">
    </grid-layout>
    <Form v-if="formVisible" ref="Form" @close="closeForm"/>
    <AddBox :visible.sync="addVisible" :webType="currWebType" @add="handleAdd"/>
    <Preview v-if="previewVisible" ref="preview" @close="previewVisible=false"/>
    <DownloadForm v-if="downloadFormVisible" ref="downloadForm"
                  @close="downloadFormVisible=false"/>
  </div>
</template>

<script>
import Form from './Form'
import AddBox from '@/views/generator/AddBox'
import DownloadForm from '../DownloadForm'
import Preview from '../Preview'
import mixin from '@/mixins/generator/index'
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

export default {
  name: 'generator-webForm',
  mixins: [mixin],
  components: {Form, AddBox, DownloadForm, Preview, HeadLayout, GridLayout},
  data() {
    return {
      query: {keyword: '', type: 4},
      previewVisible: false,
      downloadFormVisible: false,
      sort: 'webForm',
      tableLoading: false,
      headBtnOptions: [{
        label: "新增",
        emit: "head-add",
        btnOptType: 'add',
      }],
      searchColumns: [{
        label: '名称',
        prop: "fullName",
        span: 4,
        placeholder: '请输入名称',
      }, {
        label: this.$t('cip.desk.notice.field.title'),
        prop: "分类",
        span: 4,
        type: 'select',
        dicData: [],
        placeholder: '请选择分类',
      },],
      tableOption: {
        index: true,
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        menuWidth: 300,
        column: [
          {
            label: '名称',
            prop: "fullName",
            align: "left",
            overHidden: true,
          },
          {
            label: '编码',
            align: "center",
            prop: "enCode",
            overHidden: true,
          },
          {
            label: '分类',
            prop: "category",
            align: "center",

            overHidden: true,
          },
          {
            label: '模式',
            prop: "webType",
            dicData: [{
              label: '表单',
              value: '1'
            }, {
              label: '列表',
              value: '2'
            }],
            align: "center",
            overHidden: true,
          },
          {
            label: '启用流程',
            prop: "enableFlow",
            align: "center",
            dicData: [{
              label: '是',
              value: '1'
            }, {
              label: '否',
              value: '2'
            }],
            overHidden: true,
          },
          {
            label: '创建人',
            prop: "creatorUser",
            align: "center",
            overHidden: true,
          },
          {
            label: '创建时间',
            prop: "creatorTime",
            align: "center",
            overHidden: true,
          },
          {
            label: '最后修改时间',
            prop: "lastModifyTime",
            align: "center",
            overHidden: true,
          }
        ]
      },
      gridRowBtn: [{
        label: '更改模式',
        emit: "row-toggleWebType",
      },{
        label: '复制模板',
        emit: "row-copy",
      },{
        label: '下载代码',
        emit: "row-download",
      },{
        label: '预览代码',
        emit: "row-preview",
      }],
      tableData: [],
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      }
    }
  },
  methods: {
    download(row) {
      this.downloadFormVisible = true
      this.$nextTick(() => {
        this.$refs.downloadForm.init(row.tables, row.id)
      })
    },
    preview(row) {
      this.previewVisible = true
      this.$nextTick(() => {
        this.$refs.preview.init(row.tables, row.id)
      })
    },
    gridHeadSearch() {

    },
    gridHeadEmpty() {

    }
  }
}
</script>
