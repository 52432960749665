var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center SNOWS-dialog-add",
          attrs: {
            title: _vm.webType ? "更改模式" : "新建功能",
            "close-on-click-modal": false,
            "lock-scroll": "",
            width: _vm.webType ? "310px" : "600px",
          },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "add-main" }, [
        _vm.webType !== 1
          ? _c(
              "div",
              {
                staticClass: "add-item add-item-left",
                on: {
                  click: function ($event) {
                    return _vm.add(1)
                  },
                },
              },
              [
                _c("i", { staticClass: "add-icon el-icon-document" }),
                _c("div", { staticClass: "add-txt" }, [
                  _c("p", { staticClass: "add-title" }, [_vm._v("表单")]),
                  _c("p", { staticClass: "add-desc" }, [_vm._v("纯表单设计")]),
                ]),
              ]
            )
          : _vm._e(),
        _vm.webType !== 2 && _vm.webType !== 3
          ? _c(
              "div",
              {
                staticClass: "add-item add-item-list",
                on: {
                  click: function ($event) {
                    return _vm.add(2)
                  },
                },
              },
              [
                _c("i", { staticClass: "add-icon icon-ym icon-ym-sysMenu" }),
                _c("div", { staticClass: "add-txt" }, [
                  _c("p", { staticClass: "add-title" }, [_vm._v("列表")]),
                  _c("p", { staticClass: "add-desc" }, [
                    _vm._v("表单+列表设计"),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }